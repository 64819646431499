import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Section = dynamic(() => import('./base/section'))
const Image = dynamic(() => import('./base/image'))
const Video = dynamic(() => import('./base/video'))

// Components
const Header = dynamic(() => import('./widgets/header'))
const Footer = dynamic(() => import('./widgets/footer'))
const Banner = dynamic(() => import('./widgets/banner'))
const Bracket = dynamic(() => import('./widgets/bracket'))
const SoonFestivals = dynamic(() => import('./widgets/soon-festivals'))
const FestivalGallery = dynamic(() => import('./widgets/festival-gallery'))
const SocialsShare = dynamic(() => import('./widgets/socials-share'))
const Festivals = dynamic(() => import('./widgets/festivals'))
const FestivalList = dynamic(() => import('./widgets/festival-list'))
const FestivalLocation = dynamic(() => import('./widgets/festival-location'))
const DetayContent = dynamic(() => import('./widgets/detay-content'))
const DetayGallery = dynamic(() => import('./widgets/detay-gallery'))
const LocationMap = dynamic(() => import('./widgets/location-map'))
const Category = dynamic(() => import('./widgets/category'))
const Subscribe = dynamic(() => import('./widgets/subscribe'))
const Spreat = dynamic(() => import('./widgets/spreat'))
const EventList = dynamic(() => import('./widgets/events/event-list'))
const FestivalBanner = dynamic(() => import('./widgets/festival-banner'))
const EventsRoad = dynamic(() => import('./widgets/events-road'))
const SimilarEventList = dynamic(
  () => import('./widgets/events/similar-event-list')
)
const Contact = dynamic(() => import('./widgets/contact'))

const AboutUsHero = dynamic(
  () => import('./widgets/about-us-page/about-us-hero/AboutUsHero')
)
const AboutUsPath = dynamic(
  () => import('./widgets/about-us-page/about-us-path/AboutUsPath')
)
const AboutUsImageGallery = dynamic(
  () =>
    import('./widgets/about-us-page/about-us-image-gallery/AboutIsImageGallery')
)
const AboutUsVideoGallery = dynamic(
  () =>
    import('./widgets/about-us-page/about-us-video-gallery/AboutUsVideoGallery')
)
const AboutUsCategories = dynamic(
  () => import('./widgets/about-us-page/about-us-categories/AboutUsCategories')
)
const AboutUsGallery = dynamic(
  () => import('./widgets/about-us-page/about-us-gallery/AboutUsGallery')
)
const AccouncementList = dynamic(
  () => import('./widgets/announcement/announcement-list')
)

const AccouncementDetail = dynamic(
  () => import('./widgets/announcement/announcement-detail')
)

const NewsDetail = dynamic(() => import('./widgets/news/news-detail'))

const NewsList = dynamic(() => import('./widgets/news/news-list'))
const OtherNewsList = dynamic(() => import('./widgets/news/other-news'))
const CustomModal = dynamic(() => import('./widgets/custom-modal'))
const FestivalCalendar = dynamic(() => import('./widgets/festival-calendar'))
const LezzetDuraklari = dynamic(() => import('./widgets/lezzet-duraklari'))

export default function getter(name: string) {
  switch (name) {
    case 'container':
      return Container
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video

    case 'kty-header':
      return Header
    case 'kty-footer':
      return Footer
    case 'kty-banner':
      return Banner
    case 'kty-bracket':
      return Bracket
    case 'kty-soon-festivals':
      return SoonFestivals
    case 'kty-festival-gallery':
      return FestivalGallery
    case 'kty-socials-share':
      return SocialsShare
    case 'kty-festivals':
      return Festivals
    case 'kty-festival-location':
      return FestivalLocation
    case 'kty-detay-content':
      return DetayContent
    case 'kty-detay-gallery':
      return DetayGallery
    case 'kty-location-map':
      return LocationMap
    case 'kty-categories':
      return Category
    case 'kty-subscribe':
      return Subscribe
    case 'kty-spreat':
      return Spreat
    case 'kty-event-list':
      return EventList
    case 'kty-about-us-hero':
      return AboutUsHero
    case 'kty-about-us-path':
      return AboutUsPath
    case 'kty-about-us-image-gallery':
      return AboutUsImageGallery
    case 'kty-similir-event-list':
      return SimilarEventList
    case 'kty-about-us-video-gallery':
      return AboutUsVideoGallery
    case 'kty-about-us-categories':
      return AboutUsCategories
    case 'kty-contact':
      return Contact
    case 'kty-festival-list':
      return FestivalList
    case 'kty-about-us-gallery':
      return AboutUsGallery
    case 'kty-festival-banner':
      return FestivalBanner
    case 'kty-accouncement-list':
      return AccouncementList
    case 'kty-announcement-detail':
      return AccouncementDetail
    case 'kty-news-detail':
      return NewsDetail
    case 'kty-news-list':
      return NewsList
    case 'kty-events-road':
      return EventsRoad
    case 'kty-other-news-list':
      return OtherNewsList
    case 'custom-modal':
      return CustomModal
    case 'festival-calendar':
      return FestivalCalendar
    case 'lezzet-duraklari-listesi':
      return LezzetDuraklari
  }
}
